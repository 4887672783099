// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-input-container {
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  width: 40%;
}
.search-input-container input {
  flex-grow: 1;
  font-size: 14px;
  height: 35px;
}
.search-input-container .search-btn {
  align-items: center;
  background: #0066b2 0% 0% no-repeat padding-box;
  padding: 10px 20px;
  height: 35px;
  color: white;
  font-family: "GothamBold", Arial, sans-serif;
  font-size: 14px;
  cursor: pointer;
  margin-left: 0;
}
.search-input-container .search-btn:hover {
  background-color: #0b2d71;
}
.search-input-container .icon-remove:before {
  margin-right: 35px;
}
.search-input-container .clear-button {
  height: 36px;
  width: 20px;
  margin-left: -5%;
  background-color: transparent;
}
.search-input-container .clear-button .button {
  border: none;
}
.search-input-container .clear-button .button:hover {
  color: #e21836;
}

:host th {
  font-weight: bold;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
