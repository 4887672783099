import { Component, OnInit, Input } from '@angular/core';
import {
  BranchTableViewModel,
  BranchTableCellViewModel,
} from '../../models/autogenerated-piping';

@Component({
  selector: 'app-branch-table-display',
  templateUrl: './branch-table-display.component.html',
  styleUrls: ['./branch-table-display.component.scss'],
})
export class BranchTableDisplayComponent implements OnInit {
  @Input() branchTableVM: BranchTableViewModel;
  branchSizes: number[] = [];
  headerSizes: number[] = [];
  cellArrayRaw: BranchTableCellViewModel[][] = [];
  cellArray: BranchTableCellViewModel[][] = [];
  colCount = 1;
  rowCount = 1;
  higlightBranchIndex: number = null;
  highlightHeaderIndex: number = null;
  freeTextNotes: string[] = [];

  ngOnInit(): void {
    this.createCellArray();

    if (this.branchTableVM.branchTablePipeClassViewModels !== null) {
      this.branchTableVM.branchTablePipeClassViewModels.forEach((btpcvm) => {
        if (btpcvm.freeTextNote !== null) {
          this.freeTextNotes.push(btpcvm.freeTextNote);
        }
      });
    }
  }

  private createCellArray() {
    this.getBranchSizes();
    this.getHeaderSizes();
    this.populateCellArrayRows();
  }

  private getBranchSizes() {
    const uniqueBranchSizesSet = new Set(
      this.branchTableVM.cells.map((cell) => cell.branchSize)
    );
    this.branchSizes = [...uniqueBranchSizesSet].sort((a, b) => a - b);
    this.rowCount = this.branchSizes.length;
  }

  private getHeaderSizes() {
    const uniqueHeaderSizesSet = new Set(
      this.branchTableVM.cells.map((cell) => cell.headerSize)
    );
    this.headerSizes = [...uniqueHeaderSizesSet].sort((a, b) => b - a);
    this.colCount = this.headerSizes.length;
  }

  private populateCellArrayRows() {
    for (const branchSize of this.branchSizes) {
      const cellRowArray = [];
      for (const cell of this.branchTableVM.cells) {
        if (cell.branchSize === branchSize) {
          cellRowArray.push(cell);
        }
      }
      cellRowArray.sort((a, b) => b.headerSize - a.headerSize);
      this.cellArrayRaw.push(cellRowArray);
    }
    this.sortCellArrayRaw();
    this.populateCellArray();
  }

  private populateCellArray() {
    for (const row of this.cellArrayRaw) {
      this.populateCellRow(row);
    }
  }

  private populateCellRow(row: BranchTableCellViewModel[]) {
    const cellRow: BranchTableCellViewModel[] = [];
    for (const size of this.headerSizes) {
      let found = false;
      for (const cell of row) {
        if (cell.headerSize == size && !found) {
          found = true;
          cellRow.push(cell);
        }
      }
    }
    this.cellArray.push(cellRow);
  }

  private sortCellArrayRaw() {
    this.cellArrayRaw.sort((a, b) => {
      if (a[0].branchSize > b[0].branchSize) {
        return -1;
      }
      if (a[0].branchSize < b[0].branchSize) {
        return 1;
      }
      return 0;
    });
  }

  getDataCellClass(cell: BranchTableCellViewModel): string{
    if(cell.code === " "){
      return "empty-cell";
    } else {
      return 'data-cell';
    }
  }

  getBranchSizeCellClass(cell: number): string {
    if (cell === this.higlightBranchIndex) {
      return 'header-branch-size-cell-highlight';
    } else {
      return 'header-branch-size-cell';
    }
  }

  getHeaderSizeCellClass(cell: number): string {
    if (cell == this.highlightHeaderIndex) {
      return 'header-branch-size-cell-highlight';
    } else {
      return 'header-branch-size-cell';
    }
  }

  public getBranchCodeFromCell(cell: BranchTableCellViewModel): string {
    return cell.code;
  }

  onCellMouseOver(event: MouseEvent, cell: BranchTableCellViewModel) {
    const targetElement = event.target as HTMLElement;
    if (cell.code !== ' ') {
      targetElement.classList.add('data-cell-hover');
    }

    this.highlightHeaderIndex = cell.headerSize;
    this.higlightBranchIndex = cell.branchSize;
  }

  onCellMouseLeave(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    targetElement.classList.remove('data-cell-hover');
    this.highlightHeaderIndex = null;
    this.higlightBranchIndex = null;
  }
}
