// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  padding: 2%;
  overflow: auto;
}

.table-name-container {
  height: 20%;
  display: flex;
  justify-content: space-between;
}
.table-name-container #title {
  font-size: xx-large;
  font-weight: bold;
}
.table-name-container #mat-close-btn {
  height: 36px;
  width: 20px;
  border: none;
  background-color: white;
}
.table-name-container #mat-close-btn fa-icon {
  color: #707070;
}

.input-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  margin-top: 3%;
}

.sub-container {
  display: flex;
  flex-direction: row;
  width: 30%;
}
.sub-container mat-form-field {
  width: 80%;
}

.prop-label-small {
  font-size: smaller;
}

.set-table-container {
  margin-top: 10%;
  white-space: nowrap;
}
.set-table-container .set-table-button {
  background-color: white;
  border: none;
}
.set-table-container .set-table-button-text {
  color: #0066b2;
}

.table-container {
  width: 90%;
}
.table-container .highlight-input-element {
  width: 30px;
}
.table-container .data-cell {
  color: #0b2d71;
  background-color: #edeaea;
  border: solid 3px #8c8f93;
  text-align: center;
  min-width: 55px;
  max-width: 55px;
  white-space: nowrap;
}
.table-container .branch-size-label {
  padding: auto;
  background-color: white;
  text-align: center;
  border: solid 2px #8c8f93;
  width: 5%;
  white-space: nowrap;
}
.table-container .rotate-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.table-container .rotate-container .rotate-letter {
  display: flex;
  margin: 0;
}
.table-container .rotate-container .rotate-letter p {
  transform: rotate(90deg);
  margin: 0;
}
.table-container .empty-cell {
  background-color: white;
  border: none;
  min-width: 55px;
  max-width: 55px;
  white-space: nowrap;
}
.table-container .highlight-cell {
  background-color: #0066b2;
  border: solid 3px #8c8f93;
  min-width: 55px;
  max-width: 55px;
  white-space: nowrap;
}
.table-container .empty-cell-bottom-left {
  background-color: white;
  border-bottom: solid 2px #8c8f93;
  border-left: solid 2px #8c8f93;
  min-width: 55px;
  max-width: 55px;
  white-space: nowrap;
}
.table-container .empty-cell-top-left {
  background-color: white;
  border-left: solid 2px #8c8f93;
  min-width: 55px;
  max-width: 55px;
  white-space: nowrap;
}
.table-container .empty-cell-right {
  background-color: white;
  border-bottom: solid 2px #8c8f93;
  min-width: 55px;
  max-width: 55px;
  white-space: nowrap;
}
.table-container .header-size-label {
  background-color: white;
  text-align: center;
  border-right: solid 2px #8c8f93;
  border-bottom: solid 2px #8c8f93;
  border-left: solid 2px #8c8f93;
}

.common-notes-container .mat-mdc-form-field {
  min-width: 100%;
}
.common-notes-container .common-notes-search-input {
  width: 100%;
}

.legend-container mat-form-field {
  width: 350%;
}
.legend-container .legend-row {
  display: flex;
  flex-direction: row;
}
.legend-container .legend-row .legend-row-element {
  margin-right: 10px;
}

.lower-container {
  display: flex;
  justify-content: space-between;
}
.lower-container button {
  display: inline-block;
  width: 40%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
