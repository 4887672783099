import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { faSort, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { EsraPipingAPIClient, SimpleRevisionViewModel } from "src/app/shared/models/autogenerated-piping";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { SnackBarService } from "src/app/shared/services/snack-bar.service";

@Component({
    selector: 'app-techmod-create',
    templateUrl: './techmod-create.component.html',
    styleUrls: ['./techmod-create.component.scss']
})

export class TechModCreateComponent implements OnInit{
  constructor(
    public dialogRef: MatDialogRef<TechModCreateComponent>,
    private esraPipingApiClient: EsraPipingAPIClient,
    private snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}
    
  @Input() selectedItems: SimpleRevisionViewModel[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  

  totalCount: number;
  currentPage = 0;
  pageSize = 10;
  pageSizes = [2, 5, 10, 25, 50, 100];

  faWindowClose = faWindowClose;
  faSort = faSort;
  TechModSteps = 0;
  columnNames: string[] = ['Title', 'Status'];
  title ="Issue Multiple Specifications";
  sortName = false;
  sortStatus = false;
  dataSource: MatTableDataSource<SimpleRevisionViewModel>;

  ngOnInit() {
    this.selectedItems = this.data.selectedItems;
    this.data.paginator = this.paginator;
    this.totalCount = this.selectedItems.length;   
  }
  
  updatePage(event: PageEvent) {
    this.totalCount = event.length;
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.updateSelectedItems();
  }

  updateSelectedItems() {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.selectedItems = this.data.selectedItems.slice(startIndex, endIndex);
  }

  displayWarning() {
    return this.selectedItems.some(item => item.status === 0 || item.status === 1); 
  }

  reloadPage() {
    this.dialogRef.close('reload');
  }

  cancel(): void {
    this.dialogRef.close();
  }

  submit(): void {
    for (const revision of this.selectedItems) {
      const revisionId = revision.id;
      this.esraPipingApiClient
        .publishRevision(revisionId).subscribe({
          next: () => {
            this.TechModSteps = 1;
          },
          error: (err) => {
            this.snackBarService.showSnackBar(true, err.response, 'mat-snack-bar-error')
          }
        });
    }
  }

  sortColumns(columnName: string) {
    if (columnName === 'Name') {
        if (this.sortName) {
            this.dataSource.data.sort((a, b) => b.name.localeCompare(a.name));    
        } else {
            this.dataSource.data.sort((a, b) => a.name.localeCompare(b.name));
        }
        this.sortName = !this.sortName;
    } else if (columnName === 'Status') {
        if (this.sortStatus) {
            this.dataSource.data.sort((a, b) => a.status - b.status);    
        } else {
            this.dataSource.data.sort((a, b) => b.status - a.status);
        }
        this.sortStatus = !this.sortStatus;
    }
  }
}