import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { first, tap } from 'rxjs';
import { BranchTableCodeViewModel, EsraPipingAPIClient } from 'src/app/shared/models/autogenerated-piping';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-branch-table-code-add-edit',
  templateUrl: './branch-table-code-add-edit.component.html',
  styleUrls: ['./branch-table-code-add-edit.component.scss']
})
export class BranchTableCodeAddEditComponent {

  constructor(
    private esraApiClient: EsraPipingAPIClient, 
    private snackBarService: SnackBarService,
    private dialogRef: MatDialogRef<BranchTableCodeAddEditComponent>,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {
      branchTableCode: BranchTableCodeViewModel,
      action: string
    }
  ) { 
    if(data) {
      this.isEditMode = data.action === 'create'? false : true;
      if(this.isEditMode) {
        this.branchTableCodeViewModel = data.branchTableCode;
      } else {
        this.branchTableCodeViewModel = new BranchTableCodeViewModel();
      }
    }
  }
  

  faWindowClose = faWindowClose;
  inputFormGroup: FormGroup;
  branchTableCodeViewModel: BranchTableCodeViewModel;
  isEditMode = false;
  title = "Branch Table Code";
  modalStringReply: string;
  genericMissingValueMessage = "This is a required field";
  submitClicked = false;
  businessUnits = ['All'];

  ngOnInit(): void {
    this.initializeFormControls();
    this.esraApiClient.getAllBusinessUnits()
      .pipe(first(), 
        tap(businessUnits => this.businessUnits = this.businessUnits.concat(businessUnits))).subscribe();
  }

  initializeFormControls(): void {
    const businessUnit = (!this.branchTableCodeViewModel.businessUnit || this.branchTableCodeViewModel.businessUnit === "") ? "All" : this.branchTableCodeViewModel.businessUnit;
    this.inputFormGroup = this.formBuilder.group({
        code: new FormControl(this.branchTableCodeViewModel.code, [Validators.required]),
        description: new FormControl(this.branchTableCodeViewModel.description, [Validators.required]),
        businessUnits: new FormControl(businessUnit, [Validators.required]),
    })
  }

  save() {
    this.submitClicked = true;
    if (this.inputFormGroup.valid) {
      let businessUnit = this.inputFormGroup.get("businessUnits").value;
      businessUnit = (businessUnit === null || businessUnit === '' || businessUnit === 'All') ? null : businessUnit;
      const businessUnitChanged = businessUnit !== this.branchTableCodeViewModel.businessUnit;

      this.udateModelFromForm(businessUnit);
      //create new code
      if (this.data.action === "create") {
        this.createBranchTableCode();
      }
      //edit code when Business unit has not changed
      else if (this.data.action === "edit" && !businessUnitChanged) {
        this.updateBranchTableCode();
        //in case of edit when business unit has changed it means that
        // you are creating new branch table code for specific Business Unit
      } else if (this.data.action === "edit" && businessUnitChanged) {
        this.createBranchTableCode();
      } 
    } else {
      this.inputFormGroup.markAllAsTouched();
    }
  }

  udateModelFromForm(businessUnit: string) {
    this.branchTableCodeViewModel.businessUnit = businessUnit;
    this.branchTableCodeViewModel.code = this.inputFormGroup.get('code').value;
    this.branchTableCodeViewModel.description = this.inputFormGroup.get('description').value;
    this.branchTableCodeViewModel.description = this.inputFormGroup.get('description').value;
  }


  private createBranchTableCode() {
    this.esraApiClient.createBranchTableCode(this.branchTableCodeViewModel).subscribe({
      next: () => {
        this.snackBarService.showSnackBar(true, 'New Branch Table Code with code: ' + this.branchTableCodeViewModel.code + ' Created Successfully', 'mat-snack-bar-info');
        this.dialogRef.close();
      },
      error: (err) => {
        this.snackBarService.showSnackBar(true, err.response, 'mat-snack-bar-error')
      }
    })
  }

  private updateBranchTableCode() {
    this.esraApiClient.updateBranchTableCode(this.branchTableCodeViewModel).subscribe({
      next: () => {
        this.snackBarService.showSnackBar(true, 'Branch Table Code with code: ' + this.branchTableCodeViewModel.code + ' Updated Successfully', 'mat-snack-bar-info');
        this.dialogRef.close();
      },
      error: (err) => {
        this.snackBarService.showSnackBar(true, err.response, 'mat-snack-bar-error')
      }
    });
  }

}
