// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  padding: 2%;
  display: flex;
  flex-direction: column;
}

#preview {
  padding-right: 0%;
  float: left;
}

#mat-close-btn {
  height: 36px;
  width: 20px;
  float: right;
  border: none;
  color: black;
  background-color: white;
}

.table-name {
  font-size: 25px;
  font-weight: bolder;
  padding-left: 0%;
  margin-left: 0%;
  margin-bottom: 2%;
  float: left;
}

.temp-headings {
  color: red;
}

#table-scroll {
  overflow: auto;
}

#top-header {
  background-color: #e3e3e3;
  color: black;
  font-weight: 1000;
  border-right: solid 2px lightgray;
  border-left: solid 2px lightgray;
  border-top: solid 2px lightgray;
  width: 10%;
  left: 0;
  position: sticky;
  text-align: center;
}

#lower-header {
  background-color: #e3e3e3;
  color: black;
  font-weight: 1000;
  border-right: solid 2px lightgray;
  border-left: solid 2px lightgray;
  border-bottom: solid 2px lightgray;
  border-top: solid 2px lightgray;
  width: 10%;
  left: 0;
  position: sticky;
  text-align: center;
}

#top-cell {
  border-right: solid 2px lightgray;
  border-bottom: solid 2px lightgray;
  border-top: solid 2px lightgray;
  background-color: white;
}

#lower-cell {
  border-right: solid 2px lightgray;
  border-bottom: solid 2px lightgray;
  background-color: white;
}

#source-notes #additional-notes {
  display: inline;
}

.common-notes {
  color: #0066b2;
  font-weight: bold;
}

.no-space-line {
  margin: 0;
  padding: 0;
}

.note-heading {
  margin: 0;
  padding: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
