import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from './sample/home/home.component';
import { MsalComponent } from './core/components/msal.component';
import { NotAuthorizedComponent } from './sample/not-authorized/not-authorized.component';
import { WeldingSearchComponent } from './search/pages/welding-search/welding-search.component';
import { MyWorkspaceComponent } from './my-workspace/pages/my-workspace/my-workspace.component';
import { CoatingSolutionSearchComponent } from './search/pages/coating-solution-search/coating-solution-search.component';
import { ContactUsComponent } from './help/contact-us.component';
import { CoatingsRevisionSummaryComponent } from './revisions/pages/coatings-revision-summary/coatings-revision-summary.component';
import { AllRevisionsComponent } from './revisions/pages/all-revisions/all-revisions.component';
import { EditCoatingSystemComponent } from './revisions/dialogues/edit-coating-system/edit-coating-system.component';
import { EditCoatingProductComponent } from './revisions/dialogues/edit-coating-product/edit-coating-product.component';
import { AllWeldingRevisionsComponent } from './revisions/pages/all-welding-revisions/all-welding-revisions.component';
import { WeldingRevisionSummaryComponent } from './revisions/pages/welding-revision-summary/welding-revision-summary.component';
import { SpecExportComponent } from './revisions/spec-export/spec-export.component';
import { UpdateCoatingSolutionComponent } from './revisions/pages/update-coating-solution/update-coating-solution.component';
import { DictionariesComponent } from './admintool/piping/dictionaries/dictionaries.component';
import { ComponentCatalogComponent } from './admintool/piping/dictionaries/component-catalog/component-catalog.component';
import { CommonnotesComponent } from './admintool/piping/dictionaries/common-notes/common-notes.component';
import { ServicesComponent } from './admintool/piping/dictionaries/services/services.component';
import { PressuretemperatureComponent } from './admintool/piping/dictionaries/pressure-temperature/pressure-temperature.component';
import { BranchComponent } from './admintool/piping/dictionaries/branch/branch.component';
import { PipingSearchComponent } from './search/pages/piping-search/piping-search.component';
import { PressureTempTableComponent } from './piping-valves/pressure-temp-table/pressure-temp-table.component';
import { PipingTabsParentComponent } from './piping-valves/piping-tabs-parent/piping-tabs-parent.component';
import { ValveSummaryPageComponent } from './piping-valves/valve-summary-page/valve-summary-page.component';
import { ReleasesAndRevisionsComponent } from './piping-valves/piping-releases-and-revisions/piping-releases-and-revisions.component';
import { PipingAdminSearchComponent } from './search/pages/piping-admin-search/piping-admin-search.component';
import { ServiceDetailsComponent } from './admintool/piping/dictionaries/service-details/service-details.component';
import { productionGuard, userInRole } from './core/guards/guards';
import { Esra1CompatibilityComponent } from './piping-valves/esra1-compatibility/esra1-compatibility.component';
import { BranchTableCodesComponent } from './admintool/piping/dictionaries/branch-table-codes/branch-table-codes.component';
import { CommonNotesAddEditComponent } from './admintool/piping/dictionaries/dialogs/common-notes-add-edit/common-notes-add-edit.component';
import { ServiceAddEditComponent } from './admintool/piping/dictionaries/dialogs/service-add-edit/service-add-edit.component';
import { ServiceDetailsAddEditComponent } from './admintool/piping/dictionaries/dialogs/service-details-add-edit/service-details-add-edit.component';
import { PressureTempEditComponent } from './admintool/piping/dictionaries/dialogs/pressure-temp-edit/pressure-temp-edit.component';
import { BranchCreateComponent } from './admintool/piping/dictionaries/dialogs/branch-create/branch-create.component';
import { Constants } from './shared/constants/constants';

const routes: Routes = [
  {
    // Needed for hash routing
    path: 'code',
    component: MsalComponent,
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [MsalGuard],
  },
  {
    path: '403',
    component: NotAuthorizedComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'coatingsolutionsearch',
    component: CoatingSolutionSearchComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'weldingsearch',
    component: WeldingSearchComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'myworkspace',
    component: MyWorkspaceComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'spec-export',
    component: SpecExportComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'contactus',
    component: ContactUsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'coatingsrevision/:id',
    component: CoatingsRevisionSummaryComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'allrevisions',
    component: AllRevisionsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'updatecoatingsolution/:id/:revisionid',
    component: UpdateCoatingSolutionComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'coatingsystem/:coatingsystemid/:revisionid',
    component: EditCoatingSystemComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'coatingproduct/:coatingproductid/:revisionid',
    component: EditCoatingProductComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'weldingrevision/all',
    component: AllWeldingRevisionsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'weldingrevision/:id',
    component: WeldingRevisionSummaryComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'weldingrevision/:id',
    component: WeldingRevisionSummaryComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'piping/dictionaries',
    component: DictionariesComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'commonnotes',
        component: CommonnotesComponent,
        canActivate: [MsalGuard, userInRole(Constants.admin)],
      },
      {
        path: 'services',
        component: ServicesComponent,
        canActivate: [MsalGuard, userInRole(Constants.admin)],
      },
      {
        path: 'servicedetails',
        component: ServiceDetailsComponent,
        canActivate: [MsalGuard, userInRole(Constants.admin)],
      },
      {
        path: 'pressureandtemperature',
        component: PressuretemperatureComponent,
        canActivate: [MsalGuard, userInRole(Constants.admin)],
      },
      {
        path: 'branch',
        component: BranchComponent,
        canActivate: [MsalGuard, userInRole(Constants.admin)],
      },
      {
        path: 'branch-table-codes',
        component: BranchTableCodesComponent,
        canActivate: [MsalGuard, userInRole(Constants.admin)],
      },
      {
        path: 'componentcatalog',
        component: ComponentCatalogComponent,
        canActivate: [MsalGuard, userInRole(Constants.admin)],
      },
    ],
  },
  {
    path: 'piping/search',
    component: PipingSearchComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'pipingsummary/:id',
    component: PipingTabsParentComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'pipingsummary/:name/:businessUnit',
    component: PipingTabsParentComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'pressuretemperaturetable',
    component: PressureTempTableComponent,
    canActivate: [MsalGuard, productionGuard()],
  },
  {
    path: 'valveSummary/:id',
    component: ValveSummaryPageComponent,
    canActivate: [MsalGuard, productionGuard()],
  },
  {
    path: 'piping/revisionsAndReleases',
    component: ReleasesAndRevisionsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'piping/adminSearch',
    component: PipingAdminSearchComponent,
    canActivate: [MsalGuard, productionGuard()],
  },
  {
    path: 'view/:pipeclassNameAndBusinessUnit',
    pathMatch: 'prefix',
    component: Esra1CompatibilityComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'view/:pipeclassNameAndBusinessUnit/:trailingUnusedLegacyParameter',
    pathMatch: 'prefix',
    component: Esra1CompatibilityComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'piping/commonNoteAddEdit',
    component: CommonNotesAddEditComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'piping/serviceAddEdit',
    component: ServiceAddEditComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'path/serviceDetailAddEdit',
    component: ServiceDetailsAddEditComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'path/pressureTemperatureAddEdit',
    component: PressureTempEditComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'path/branchTableAddEdit',
    component: BranchCreateComponent,
    canActivate: [MsalGuard]
  },
  {
    path: '**',
    component: HomeComponent,
    canActivate: [MsalGuard],
  },
];

const isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      // Don't perform initial navigation in iframes
      initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled', // Remove this line to use Angular Universal
    }),
  ],
  //   imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
