import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent  {
  message = "Are you sure?";
  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    if (data.dialogMessage) {
      this.message = data.dialogMessage;
    }
  }
  faWindowClose = faWindowClose;

  emitConfirm(): void {
    this.dialogRef.close(true);
  }
}
